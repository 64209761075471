import React, { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ padding: "20px", paddingTop: "0" }}>
      <div
        style={{
          backgroundColor: "rgb(36 50 93)",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "white" }}>
          PILLZER (21ventures Healthtech Private Limited)
          <h2 style={{ color: "white" }}>TERMS AND CONDITIONS</h2>
        </h1>
      </div>
      <br />
      <span>
        This document (“Terms”) is an electronic record in terms of The
        Information Technology Act, 2000, and rules thereunder, as applicable,
        and the amended provisions pertaining to electronic records in various
        statutes, as amended from time to time by the Information Technology
        Act, 2000. This electronic record is generated by a computer system and
        does not require any physical or digital signatures. This document is
        published in accordance with the provisions of Rule 3(1) of the
        Information Technology (Intermediaries Guidelines and Digital Media
        Ethics Code) Rules, 2021 as amended from time to time, which requires
        publishing of the rules and regulations, privacy policy and Terms for
        access or usage of www.pillzer.health website and its mobile
        application.
      </span>{" "}
      <br />
      <span>
        <br />
        <strong>1. GENERAL</strong>
        <br />
        We, at 21ventures Healthtech private Limited, referred as 21VHPL now
        on,a company duly incorporated under the provisions of the Companies
        Act, 2013, having its registered office at No 3,2nd floor,4th A
        Cross,pipeline road,Vijayanagr,Bengaluru,Karnataka-560040.We provide
        services to all individuals accessing or using our app and website. The
        website name www.pillzer.health, an internet-based portal, and
        “PillzerX”, a mobile application, is run, operated, and maintained by
        21VHPL.Company may assign, transfer, and subcontract its rights and/or
        obligations under these Terms and Conditions to any third party, as it
        may deem fit, and you shall continue to be bound by these Terms and
        Conditions in the event of such assignment, transfer, or subcontracting.
        Our Platform is operated, and services are provided in compliance with
        the laws in India and 21VHPL shall not be liable to provide any Services
        availed by you in locations outside India. If you access our services
        from locations outside India, you do so at your own risk and you are
        solely liable for compliance with applicable local laws. The User agrees
        to use the service to authorise an individual and get the services from
        the third party on his/her behalf. Where you use any third-party website
        or the services of any third party, you may be subject to alternative or
        additional terms and conditions of use and privacy policies of the
        respective third party/s. Any accessing or browsing of the Platform and
        using the Services (as defined in these Terms and Conditions) indicates
        your Agreement to all the terms and conditions in this Agreement. If you
        disagree with any part of the Terms and Conditions, then you may
        discontinue access or use of the Platform.
      </span>{" "}
      <br />
      <br />
      <span>
        <strong>2. ELIGIBILITY</strong>
        <br />
        <span>
          When You use the Platform, You represent that You meet the following
          primary eligibility criteria:
        </span>
        <ul style={{ listStyleType: "lower-alpha" }}>
          <li>
            You are at least 18 years old or accessing the Platform under the
            supervision of a parent or guardian, who in such a case will be
            deemed as the recipient / end-user of the Services (as defined in
            these Terms and Conditions) for the purpose of these Terms and
            Conditions.
          </li>
          <li>
            If Your age is below that of 18 years, your parents or legal
            guardians can transact on behalf of You if they are registered
            users. You are prohibited from purchasing any material the sale or
            purchase of which to/by minors is prohibited and which is for
            consumption by adults only.
          </li>
          <li>
            You are legally competent to contract, and otherwise competent to
            receive the Services (as defined in these Terms and Conditions).
            Persons who are "incompetent to contract" within the meaning of the
            Indian Contract Act, 1872, including un-discharged insolvent, etc.,
            are not eligible to use the Platform.
          </li>
          <li>
            You have not been previously suspended or removed by 21VHPL or any
            other 21VHPL subsidiaries or disqualified for any other reason, from
            availing the Services.
          </li>
          <li>
            21VHPL reserves the right to terminate your membership and/or refuse
            to provide You with access to the Platform if 21VHPL discovers that
            You are under the age of 18 years.
          </li>
          <li>
            Your registered id can only be utilized by You wherein Your details
            have been provided and We do not permit multiple persons to share a
            single login/ registration id.
          </li>
          <li>
            We reserve our rights to refuse access to use the Services offered
            at the Platform to new Users or to terminate access granted to
            existing Users at any time without assigning any reasons for doing
            so and you shall have no right to object to the same.
          </li>
          <li>
            Organizations, companies, and businesses may not become registered
            Users on the Platform or use the Platform through individual Users.
          </li>
          <li>
            <ul style={{ listStyleType: "lower-roman" }}>
              <li>
                You agree and acknowledge that You shall
                <br />
                (i) create only 1 (one) account;
                <br />
                (ii) provide accurate, truthful, current, and complete
                information when creating your account and in all Your dealings
                through the Platform;
                <br />
                (iii) maintain and promptly update your account information;
                <br />
                (iv) maintain the security of Your account by not sharing Your
                password with others and restricting access to Your account and
                Your computer;
                <br />
                (v) promptly notify 21VHPL if You discover or otherwise suspect
                any security breaches relating to the Platform;
                <br />
                (vi) take responsibility for all the activities that occur under
                Your account and accept all risk of unauthorized access.
              </li>
            </ul>
          </li>
          <li>
            We at Our discretion, reserve the right to permanently or
            temporarily suspend Users, to bar their use and access of the
            Platform, at any time while We investigate complaints or alleged
            violations of these Terms of Use or any Services, or for any other
            reason.
          </li>
        </ul>
      </span>{" "}
      <br />
      <span>
        <strong>3. OUR SERVICES</strong>
        <br />
        <span>
          Through the Platform, We provide You with the following services
          (“Services”):
        </span>
        <span>
          <ul style={{ listStyleType: "lower-alpha" }}>
            <li>
              Creating and maintaining user accounts:
              <ul style={{ listStyleType: "lower-roman" }}>
                <li>
                  Users need to register on the Platform in order to use the
                  Platform or the Services provided through the Platform. In
                  order to register, You have to provide certain personal
                  details including but not limited to Your name, phone number,
                  email address, birth date, gender, etc.
                </li>
              </ul>
            </li>
            <li>
              Scheduling an appointment for medical consultation:
              <ul style={{ listStyleType: "lower-roman" }}>
                <li>
                  You can book an appointment for a medical consultation with a
                  healthcare or wellness service provider/doctor/physician
                  (“HSP”) listed on the Platform. Medical consultations on the
                  Platform shall be available for multi specialties.
                </li>
                <li>
                  You will receive a confirmation of appointment for medical
                  consultation with a HSP, on the Platform and/or via SMS and/or
                  email / online communication messaging services. We reserve
                  the right to reschedule or cancel an appointment without any
                  prior notice. The time provided for a consultation to you is
                  indicative and actual consultation time may change depending
                  on the consulting HSP’s discretion or other circumstances, for
                  which You shall be accordingly communicated. You agree that
                  there might be technological/logistic/unforeseen circumstances
                  that might lead to delay or cancellation of the consultation
                  for which appropriate resolution either as rescheduling/
                  refund shall be provided. For any support related to issue/s
                  faced by You pre/during / post consultation, You can reach Us
                  through various channels as set out on the Platform. Your
                  consulting HSP reserves the right to provide a free review,
                  post-medical consultation.
                </li>
              </ul>
            </li>
            <li>
              Holding medical consultations:
              <ul style={{ listStyleType: "lower-roman" }}>
                <li>
                  You can have either Physical or Online Medical Consultation
                  via audio call or video call or chat on the Platform with the
                  HSP selected by You /allocated to You: In case of online
                  medical consultation, please ensure that You are equipped with
                  proper video conference equipment and internet connectivity as
                  required for the Service You want to avail. In case of
                  inadequate video conference equipment, technical error, or
                  defective internet connectivity, We reserve the right to
                  reschedule or cancel Your appointment without any notice. You
                  understand and acknowledge that there will not be any physical
                  examination involved in Online Medical Consultation services
                  shall be provided remotely in accordance with these Terms and
                  Conditions. The opinion delivered by the HSP shall solely be
                  based on the verbal communication between the concerned HSP
                  and the patient, and any test reports and other information
                  provided/uploaded by You on the Platform. Your medical records
                  may be viewed by the HSP on the chat facility provided on the
                  Platform in the course of medical consultations and will
                  remain accessible to You for a specific number of days after a
                  particular consultation concludes, as determined by the HSP.
                </li>
                <li>
                  The Services are not meant in any way for emergency and
                  life-threatening conditions. It is advised to take the patient
                  to the nearest hospital in such cases or as advised by HSP.
                </li>
                <li>
                  In case the HSP is not responding You are advised to get in
                  touch with our customer care.
                </li>
              </ul>
            </li>
            <li>
              Receiving e-prescription from the HSP based on the medical
              consultation:
              <ul style={{ listStyleType: "lower-roman" }}>
                <li>
                  You shall receive an e-prescription on the Platform or any
                  other communication modes after consultation with the
                  concerned HSP.
                </li>
              </ul>
            </li>
            <li>
              Accessing Your medical records on the Platform:
              <ul style={{ listStyleType: "lower-roman" }}>
                <li>
                  You can access your medical records on the Platform or request
                  us through preferred communication mode.
                </li>
              </ul>
            </li>
          </ul>
        </span>
      </span>
      <br />
      <span>
        <strong>4. YOUR USE OF THE PLATFORM</strong>
        <br />
        <span>
          As an end-user and recipient of Services, when You use the Platform,
          You agree to the following conditions of use:
        </span>
        <br />

        <span>
          A. Due diligence conditions:
          <ul style={{ listStyleType: "lower-alpha" }}>
            <li>
              You are solely responsible for the medical, health, and personal
              information you provide on the Platform, and You are requested to
              use Your discretion in providing such information.
            </li>
            <li>
              The advice or the Services provided by the HSP will depend upon
              the information You provide on the Platform. You will provide
              accurate and complete information everywhere on the Platform,
              based on which You will receive the Services.
            </li>
            <li>
              You will be solely responsible for all access to and use of this
              site by anyone using the password and identification originally
              assigned to you whether or not such access to and use of this site
              is actually authorized by you, including without limitation, all
              communications and transmissions and all obligations (including,
              without limitation, financial obligations) incurred through such
              access or use. You are solely responsible for protecting the
              security and confidentiality of the password and identification
              assigned to You.
            </li>
            <li>
              The information provided by You may be used by us for the purpose
              of Services including analysis, research, training, and disclosure
              (where required) to our affiliates, group companies, agents and
              government authorities, etc., as stated in Our Privacy Policy.
              Please separately review and consent to Our Privacy Policy,
              accordingly.
            </li>
            <li>
              The information provided by You can be retained by us and can be
              used without revealing Your identity, as per Our Privacy Policy
            </li>
            <li>
              We reserve Our rights to refuse Service or terminate accounts at
              Our discretion, if we believe that You have violated or are likely
              to violate applicable law or these Terms and Conditions.
            </li>
          </ul>
        </span>

        <span>
          B. Scope of Services:
          <ul style={{ listStyleType: "lower-alpha" }}>
            <li>
              The Services availed by You from a HSP via the Platform are an
              arrangement between You and the HSP You select. The Platform only
              facilitates connections between You and the HSP and bears no
              responsibility for the outcome of any such medical consultation or
              other Services obtained by You.
            </li>
            <li>
              We shall not be liable for deficiency or shortfall in
              Services/misdiagnosis / faulty judgment/interpretation
              error/perception error / adverse events/inefficacy of prescribed
              treatment or advice or investigation reports/validity of the
              advice or prescription or investigation reports provided by the
              HSP / unavailability of the recommended or prescribed treatment or
              medication under any condition or circumstances. Users are advised
              to use their discretion for following the advice obtained
              post-medical consultation with HSP via the Platform or post
              Services.
            </li>
            <li>
              We only facilitate the connections between You and the HSP
              established through the Platform and does not in any way
              facilitate, encourage, permit, or require you to contact the HSP
              outside the Platform. Any contact between You and the HSP through
              the Platform, will be subject to these Terms and Conditions.
            </li>
            <li>
              If You are redirected to, or visit, any other website or app from
              the Platform, including for the purposes of browsing, or when the
              placement and/or fulfillment of an order of any product/service
              (or any other aspect related thereto) takes You to a different
              website/platform, We will not be responsible for products or
              services received or purchased by You on such other website or
              platform, or any aspect of your experience on such website or any
              representations and details provided on such website/platform
              concerning products/services being displayed or sold by such
              website/platform.
            </li>
            <li>
              You may view and access the content available on the Platform
              solely for the purposes of availing the Services, and only as per
              these Terms and Conditions. You shall not modify any content on
              the Platform or reproduce, display, publicly perform, distribute,
              or otherwise use such content in any way for any public or
              commercial purpose or for personal gain.
            </li>
          </ul>
        </span>

        <span>
          C. Prohibitions:
          <ul style={{ listStyleType: "lower-alpha" }}>
            <li>
              You may not reproduce, distribute, display, sell, lease, transmit,
              create derivative works from, translate, modify, reverse-engineer,
              disassemble, decompile or otherwise exploit the Platform or any
              portion of it unless expressly permitted by 21VHPL in writing.
            </li>
            <li>
              You may not make any commercial use of any of the information
              provided on the Platform.
            </li>
            <li>
              You may not impersonate any person or entity, or falsely state or
              otherwise misrepresent your identity, age or affiliation with any
              person or entity.
            </li>
            <li>
              You may not upload any content prohibited under applicable law,
              and/or designated as “Prohibited Content” under Section 5.
            </li>
            <li>
              You may not contact or make any attempt to contact the concerned
              HSP for a consultation, follow up to a prior medical consultation
              or for any other reason outside the Platform via email, SMS,
              messaging services or any other mode of communication outside the
              authorized channels.
            </li>
            <li>
              You may not assign, transfer, or subcontract any of your rights or
              obligations under these Terms or any related order for Products to
              any third party, unless agreed upon in writing by 21VHPL.
            </li>
          </ul>
        </span>
      </span>{" "}
      <br />
      <span>
        <strong>5. PROHIBITED CONTENT</strong>
        <br />
        <span>
          You shall not upload to, distribute, or otherwise publish through the
          Platform, the following Prohibited Content, which includes any
          content, information, or other material that:
        </span>
        <br />
        <span>
          <ul style={{ listStyleType: "lower-alpha" }}>
            <li>
              Belongs to another person and which you do not own the rights to;
            </li>
            <li>
              Is harmful, harassing, blasphemous, defamatory, obscene,
              pornographic, pedophilic, invasive of another's privacy, including
              bodily privacy, insulting or harassing on the basis of gender,
              libelous, racially or ethnically objectionable, or otherwise
              inconsistent with or contrary to the laws in force;
            </li>
            <li>
              Is hateful, racially or ethnically objectionable, disparaging of
              any person;
            </li>
            <li>
              Relates to or seems to encourage money laundering or gambling;
            </li>
            <li>Harm minors in any way;</li>
            <li>
              Infringes any patent, trademark, copyright, or other proprietary
              rights;
            </li>
            <li>Violates any law in India for the time being in force;</li>
            <li>
              Deceives or misleads the addressee about the origin of your
              message and intentionally communicates any information which is
              patently false or misleading in nature but may reasonably be
              perceived as a fact;
            </li>
            <li>
              Communicates any information which is grossly offensive or
              menacing in nature;
            </li>
            <li>Impersonates another person;</li>
            <li>
              Contains software viruses or any other computer code and malicious
              programs;
            </li>
            <li>
              File or program designed to interrupt, destroy or limit the
              functionality of any computer resource;
            </li>
            <li>
              Threatens the unity, integrity, defence, security, or sovereignty
              of India, friendly relations with foreign states, or public order,
              promoting enmity between different groups on the grounds of
              religion or caste with the intent to incite violence;
            </li>
            <li>
              Is patently false and untrue, and is written or published in any
              form, with the intent to mislead or harass a person, entity or
              agency for financial gain or to cause any injury to any person;
            </li>
            <li>
              Incites any offence or prevents investigation of any offence or
              insults any other nation;
            </li>
            <li>
              Is abusive or inappropriate to the HSP conducting your medical
              consultation or any employees, consultants or technicians of
              21VHPL or any other its subsidiaries or affiliate who you may
              interact with for availing Services; and
            </li>
            <li>
              Is not relating to the medical consultation or relating to any of
              the services you avail from us.
            </li>
          </ul>
        </span>
        You also understand and acknowledge that if you fail to adhere to the
        above, we have the right to remove such information and/or immediately
        terminate your access to the Services and/or to the Platform.
      </span>
      <br />
      <br />
      <span>
        <strong>6. INDEMNITY</strong>
        <br />
        You agree and undertake to indemnify and keep indemnified 21 VHPL, its
        affiliates, the concerned HSP, representatives, and each of their
        respective directors, officers or employees (“Indemnified Persons”) and
        us for any losses, costs, charges, and expenses including reasonable
        attorney fees that the concerned Indemnified Persons may suffer on
        account of:
      </span>{" "}
      <br />
      <span>
        <ul style={{ listStyleType: "lower-alpha" }}>
          <li>
            Deficiency or shortfall in Services/misdiagnosis / faulty
            judgement/interpretation errors/perception error arising from:
            <ol style={{ listStyleType: "lower-roman" }}>
              <li>
                Your failure to provide correct and/or complete clinical
                information/history about the patient in a timely and clinically
                appropriate manner; or
              </li>
              <li>
                Suppression of material facts; or your failure to provide
                relevant clinical information about the patient; or
              </li>
              <li>
                Misinterpretation of the
                advice/prescription/diagnosis/investigation report by you; or
              </li>
              <li>Failure to follow doctor’s advice/prescription by you; or</li>
              <li>
                Failure to follow instructions of the HSP in respect of the
                Services or medical procedures prescribed by the HSP by you;
              </li>
            </ol>
          </li>
          <li>
            Incorrect or inaccurate credit/debit card details provided by you;
            or
          </li>
          <li>
            Using a credit/debit card which is not lawfully owned by you; or
          </li>
          <li>
            You permit a third party to use your password or other means to
            access your account.
          </li>
        </ul>
      </span>
      <span>
        <strong>7. LIMITATION OF LIABILITY</strong>
        <br />
        <ul style={{ listStyleType: "none" }}>
          <li>
            {" "}
            By using our Services, you confirm that You understand and agree to
            the following:
          </li>
        </ul>

        <ul style={{ listStyleType: "lower-alpha" }}>
          <li>
            The Services availed by you from an HSP via the Platform are
            provided to You by the HSP you select, and not by 21VHPL. The
            limitation of liability specified in this section also applies to
            any Services availed by You from any third-party seller of services
            listed on the Platform.
          </li>
          <li>
            The Platform only facilitates communications between You and the HSP
            and as such 21VHPL bears no responsibility for the quality and
            outcome of any such Services obtained by You from the respective
            HSP, to the extent permitted by applicable law.
          </li>
          <li>
            21VHPL itself does not provide any medical consultation or
            diagnostic services. If You receive any medical advice/investigation
            reports from an HSP you have contacted through the Platform, You are
            responsible for assessing such advice, and reports the consequences
            of acting on such advice or reports, and all post-consultation
            follow-up action, including following the HSP’s instructions.
          </li>
          <li>
            In the event that 21VHPL markets or promotes any Services to You,
            please note that such Services will be provided by the relevant HSP,
            and You are responsible for undertaking an assessment regarding the
            suitability of such Services and such HSPs for Your purposes.
            Marketing or promotion of Services should be considered as being for
            informational purposes only, and does not constitute expert advice
            on the suitability of such services for Your specific healthcare
            needs.
          </li>
        </ul>
      </span>
      <br />
      <span>
        <strong>8. DATA & INFORMATION POLICY</strong>
        <br />
        <ul style={{ listStyleType: "upper-alpha" }}>
          <li>
            We respect your right to privacy in respect of any personal
            information provided to us for the purposes of availing our
            Services.
          </li>

          <li>
            Account Deletion: Users have the right to delete their 21VHPL
            account and personal information at any time, in line with 21VHPL's
            commitment to data privacy and applicable laws. 21VHPL will delete
            the user's data within 45 days of the account deletion request, and
            no further communications will be sent. Retrieval of deleted data
            will not be possible, except upon specific user request for account
            reactivation. 21 VHPL will mark the phone number as 'inactive' after
            account deletion, allowing subsequent logins without data retrieval.
            Users can refer to an FAQ on the entire process, including how to
            delete an account and how to restore it on 21VHPL’s website.
          </li>
        </ul>
      </span>
      <span>
        <strong>9. INTELLECTUAL PROPERTY AND OWNERSHIP</strong>
        <ul style={{ listStyleType: "upper-alpha" }}>
          <li>
            All Confidential Information, including the inherent intellectual
            properties (“IP”) which has been developed by 21VHPL or its
            affiliates or by third parties under contract to 21 VHPL to develop
            same or which has been purchased by or licensed to 21VHPL, remains
            the sole and exclusive property of 21VHPL.
          </li>
          <li>
            You shall be bound by the following obligations with respect to
            ownership of Intellectual Properties :
          </li>
        </ul>
        <ul style={{ listStyleType: "lower-roman" }}>
          <ol style={{ listStyleType: "lower-roman" }}>
            <li>
              All works materials, software, documentation, methods, apparatus,
              systems and the like prepared, developed, conceived, or delivered
              as part of or in connection with the Services and all tangible
              embodiments thereof, shall be considered IP for any use of Our
              Platform under this Agreement.
            </li>
            <li>
              21VHPL shall have exclusive title and ownership rights, including
              all intellectual property rights, throughout the world in all
              Services. To the extent that exclusive title and/or ownership
              rights may not originally vest in 21VHPL as contemplated herein,
              You hereby irrevocably assigns all right, title, and interest,
              including intellectual property and ownership rights, in the
              Services, medical records, and information to 21 VHPL, and shall
              cause Your representatives to irrevocably assign to 21VHPL all
              such rights in the IP.
            </li>
            <li>
              All uses of any trademarks, service marks, and trade names in the
              Services or in the performance of the Services, and the goodwill
              associated therewith, whether by You or third parties, inures and
              shall insure to the benefit of 21VHPL.
            </li>
            <li>
              You agree not to circumvent, disable or otherwise interfere with
              security-related features of the Platform or features that prevent
              or restrict use or copying of any materials or enforce limitations
              on use of the Platform or the materials therein. The materials on
              the Platform or otherwise may not be modified, copied, reproduced,
              distributed, republished, downloaded, displayed, sold, compiled,
              posted or transmitted in any form or by any means, including but
              not limited to, electronic, mechanical, photocopying, recording or
              other means.
            </li>
            <li>
              No use of these may be made without the prior written
              authorization of 21VHPL.
            </li>
          </ol>
        </ul>
      </span>
      <span>
        <strong>10. OTHER CONDITIONS </strong>

        <ul style={{ listStyleType: "none" }}>
          <br />
          <span>Accuracy of Information Displayed</span>
          <br />
          <li>
            We have made every effort to display, as accurately as possible, the
            information provided by the relevant third parties including HSPs.
            However, We do not undertake any liability in respect of such
            information and or with respect to any other information in regard
            to which you are capable of conducting Your own due diligence to
            ascertain accuracy.
          </li>
        </ul>
      </span>
      <span>
        <strong>11. THIRD-PARTY LINKS AND RESOURCES</strong>
        <ul style={{ listStyleType: "lower-alpha" }}>
          <li>
            Where the Platform contains links to other sites and resources
            provided by third parties (including where our social media sharing
            plug-ins include links to third-party sites), these links are
            provided for your information only. We have no control over the
            contents of those websites/platforms (including without limitation
            APL Platforms) or resources and accept no responsibility for them or
            for any loss or damage that may arise from your use of them.
          </li>
          <li>
            21VHPL is neither guaranteeing nor making any representation with
            respect to the goods or services made available or sold by such
            third-party. 21VHPL does not provide any warranty or recommendation
            in relation to the products and/or services made available to you by
            such third parties during your access or use of such third-party
            website/platform including in relation to delivery, services,
            suitability, merchantability, reliability, availability or quality
            of the products and/or services.
          </li>
          <li>
            You shall not hold 21VHPL, its group entities, affiliates, or their
            respective directors, officers, employees, agents, and/or vendors
            responsible or liable for any actions, claims, demands, losses,
            damages, personal injury, costs, charges, and expenses which you
            claim to have suffered, sustained or incurred, or claim to suffer,
            sustain or incur, directly or indirectly, on account of your use or
            access of third party website/platform.
          </li>
          <li>
            21VHPL is not a party to any contractual arrangements entered into
            between you and the third-party website/platform. We are not the
            agent of the third party and such third-party website/platform is
            governed exclusively by its respective policies over which 21VHPL
            has no control.
          </li>
          <li>
            The use of such a link to visit the third-party website/platform
            implies full acceptance of these Terms and Conditions. 21VHPL shall
            not be responsible or liable, directly or indirectly, for any damage
            or loss caused or alleged to be caused by or in connection with the
            use of or reliance on any such content available on or through any
            such third-party linked website, including without limitation any
            form of transmission received from any third party website
            (including without limitation APL) or its server.
          </li>
        </ul>
      </span>
      <span>
        <strong>12. AMENDMENTS</strong>

        <ul style={{ listStyleType: "none" }}>
          <li>
            We may from time to time update or revise these Terms and
            Conditions. Every time you wish to use the Platform, please check
            the relevant Terms and Conditions and Privacy Policy to ensure you
            understand the terms that apply at that time.
          </li>
        </ul>
      </span>
      <span>
        <strong>13. FORCE MAJEURE</strong>
        <ul style={{ listStyleType: "lower-roman" }}>
          <li>
            We will not be liable for any non-compliance or delay in compliance
            with any of the obligations we assume under any contract when caused
            by events that are beyond our reasonable control (“Force Majeure”).
            Force Majeure shall include any act, event, failure to exercise,
            omission or accident that is beyond our reasonable control,
            including, among others, the following:
          </li>
          <li>Strike, lockout or other forms of protest</li>
          <li>
            Civil unrest, revolt, invasion, terrorist attack or terrorist
            threat, war (declared or not) or threat or preparation for war.
          </li>
          <li>
            Fire, explosion, storm, flood, earthquake, collapse, epidemic or any
            other natural disaster.
          </li>
          <li>
            Inability to use public or private transportation and
            telecommunication systems.
          </li>
          <li>
            Acts, decrees, legislation, regulations or restrictions of any
            government or public authority including any judicial determination.
          </li>
          <li>
            Our obligations deriving from any contracts should be considered
            suspended during the period in which Force Majeure remains in effect
            and we will be given an extension of the period in which to fulfill
            these obligations by an amount of time we shall communicate to you,
            not being less than the time that the situation of Force Majeure
            lasted.
          </li>
        </ul>
      </span>
      <span>
        <strong>14. TERMINATION</strong>
        <ul style={{ listStyleType: "upper-alpha" }}>
          <li>
            We may terminate this arrangement at any time, with or without
            cause. If you wish to terminate this arrangement, you may do so at
            any time by discontinuing your access or use of this Platform.
          </li>
          <li>
            We reserve the right to refuse the use of Services immediately in
            case your conduct is deemed by us to be in contravention of
            applicable acts, laws, rules, and regulations or these Terms and
            Conditions or considered to be unethical/immoral; and
          </li>
          <li>
            For change in law specifically, we reserve our rights to suspend our
            obligations under any contract indefinitely, and/or provide Services
            under revised Terms and Conditions.
          </li>
        </ul>
      </span>
      <span>
        <strong>
          15. APPLICABLE LAWS, JURISDICTION, AND DISPUTE RESOLUTION{" "}
        </strong>

        <ul style={{ listStyleType: "none" }}>
          <br />
          <span>
            The use of our Platform shall be governed by the laws applicable in
            India, without reference to the conflict of laws principles;
          </span>
          <br />
          <li>
            Any dispute relating to the use of our Services shall be subject to
            the exclusive jurisdiction of the courts at Bengaluru, India;
          </li>
          <li>
            If any dispute, difference, or claim arises between Us and You in
            connection with this Agreement or the validity, interpretation,
            implementation, or alleged breach of this Agreement or anything is
            done, omitted to be done pursuant to this Terms and Conditions, You
            shall first endeavour to resolve the same through conciliation and
            negotiation. However, if the dispute is not resolved through
            conciliation and negotiation within 30 days after the commencement
            of such conciliation or within such period mutually agreed in
            writing, then We may refer the dispute for resolution by arbitration
            under the Indian Arbitration and Conciliation Act, 1996 as amended
            from time to time ("the Act”) by a sole arbitrator to be appointed
            mutually by Us.{" "}
          </li>
        </ul>
      </span>
      <span>
        <strong>16. SEVERABILITY</strong>

        <ul style={{ listStyleType: "none" }}>
          <li>
            If, for any reason, a court of competent jurisdiction finds any
            provision of these Terms, or portion thereof, to be unenforceable,
            that provision shall be enforced to the maximum extent permissible
            so as to give effect to the intent of the Parties as reflected by
            that provision, and the remainder of the Terms shall continue in
            full force and effect.
          </li>
        </ul>
      </span>
      <span>
        <strong>17. WAIVER</strong>

        <ul style={{ listStyleType: "none" }}>
          <li>
            No provision of these Terms of Use shall be deemed to be waived and
            no breach excused, unless such waiver or consent shall be in writing
            and signed by 21VHPL. Any consent by 21VHPL to, or a waiver by
            21VHPL of any breach by Other Parties, whether expressed or implied,
            shall not constitute consent to, waiver of, or excuse for any other
            different or subsequent breach.
          </li>
        </ul>
      </span>
      <div
        style={{
          backgroundColor: "rgb(36 50 93)",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <h1 style={{ color: "white" }}>PillzerHealth</h1>

        <span style={{ color: "white" }}>
          Copyright © 2023, PillzerHealth. All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default TermsAndConditions;
