import React from "react";
import { HashRouter as Router, Routes, Route, Link } from "react-router-dom";
import AboutUs from "./components/AboutUs";
import TermsAndConditions from "./components/termsAndConditions";
import PrivacyPolicy from "./components/privacyAndPolicy";
import PaymentRefundPolicy from "./components/paymentAndRefundPolicy";
import ContactUs from "./components/Contact";

// ScrollToTop component
const ScrollToTop = () => {
  // Scroll to the top when the location changes
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

function App() {
  return (
    <Router>
      {/* Add the ScrollToTop component here */}
      <ScrollToTop />

      <div>
        <hr />

        <Routes>
          <Route path="/" element={<AboutUs />} />
          <Route path="/terms&conditions" element={<TermsAndConditions />} />
          <Route path="/privacy&policy" element={<PrivacyPolicy />} />
          <Route
            path="/payment&refundpolicy"
            element={<PaymentRefundPolicy />}
          />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
