import React, { useEffect } from "react";

const PrivacyAndPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ padding: "20px", paddingTop: "0" }}>
      <div
        style={{
          backgroundColor: "rgb(36 50 93)",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "white" }}>
          PILLZER (21ventures Healthtech Private Limited)
          <h2 style={{ color: "white" }}>Privacy And Policy</h2>
        </h1>
      </div>
      <br />
      <span>
        <strong>1. WHAT IS PERSONAL INFORMATION </strong>
        <br />
        <ul>
          <li style={{ listStyleType: "none" }}>
            Personal information is that information which can be used to
            directly or indirectly identify you. It includes de-identified data
            that, when linked to other information available to us, would enable
            us to identify you. Personal data does not include data that has
            been irreversibly anonymized or aggregated so that we cannot
            identify you through it, even in conjugation conjunction with other
            information. “Sensitive Personal Data or Information” means personal
            information of any individual relating to password; financial
            information such as bank account or credit card or debit card or
            other payment instrument details; physical, physiological, and
            mental health condition; sexual orientation; health information such
            as medical records and history; biometric information; any detail
            relating to the above as provided to or received by us for
            processing or storage. However, any data/information relating to an
            individual that is freely available or accessible in the public
            domain or furnished under the Right to Information Act, 2005, or any
            other law shall not qualify as Sensitive Personal Data or
            Information. By signing up on the App or proceeding to the Website,
            and / or using our Services you represent that you voluntarily
            provide us with personal information including medical and financial
            information, and consent to their collection, use, and disclosure in
            accordance with this Privacy Policy. You also represent that you are
            duly authorised by any third party (including a child or an
            employee) whose information you share with us. We shall act as per
            your representation of authority and shall not make any independent
            enquiries to ascertain the veracity of your authorisation. In the
            event you do not have sufficient authorisation you shall be solely
            responsible for your acts and omissions including sharing of
            information with us by you and the consequential processing and
            actions taken by us in accordance with this Privacy Policy.
          </li>
        </ul>
      </span>
      <br />
      <span>
        <strong>2.WHAT TYPES OF DATA DO WE COLLECT</strong>
        <br />
        <ul style={{ listStyleType: "none" }}>
          <li>
            {" "}
            The Website/App, like many other websites, uses “Cookies”. Cookies
            are small data files that a website stores on your web browser.
            These are used for the purpose of storing your preferences, previous
            activities, browsing activities, profiling, and tracking behavior on
            this Website. By visiting the App or Website, you acknowledge,
            accept, and expressly authorize the placement of cookies on your web
            browser. We recommend that you clear the cookies stored on your
            browser from time to time.
          </li>
          <li>
            {" "}
            When you sign up or register on the App or Website to use our
            Services, and during the course of actual usage of our Services, the
            types of information that will be collected by us include the
            following:
          </li>
        </ul>

        <ul style={{ listStyleType: "lower-roman" }}>
          <li>
            Contact information: Name, Address, Contact details, Email ID, Phone
            Number;
          </li>
          <li>Demographic information: Gender, Date of Birth, Nationality;</li>
          <li>
            Data regarding your usage of the Services such as search history and
            history of the appointments made by you through the use of Services;
          </li>
          <li>
            Financial information such as bank account or other payment
            instrument details and billing information other than credit card or
            debit card or payment account number, subject to applicable laws;
          </li>
          <li>
            Your browsing history including the URL of the site that you visited
            prior to visiting the Website as well as the Internet Protocol (IP)
            address of your computer (or the proxy server you used to access the
            World Wide Web), your computer operating system and type of web
            browser you are using, the name of your ISP;
          </li>
          <li>
            Any additional information that you provide to us during the use of
            the Services, through any mode of communication or during any
            interaction with our employees, doctors, technicians, consultants,
            etc., of or any other entity while availing its services;
          </li>
          <li>
            Health information such as your medical records and history which
            you voluntarily provide or is generated on the usage of any of the
            services availed by you from any entity in the 21VHPL;
          </li>
          <li>
            Information regarding your insurance coverage (such as your
            insurance carrier and insurance plan) which you voluntarily provide
            or is generated on availing any of the Services;
          </li>
          <li>
            Information regarding your physical, physiological, and mental
            health condition which you voluntarily provide or is generated on
            the usage of the Services including information such as (a)
            inpatient and emergency department data; (b) outpatient
            registration, scheduling, and encounter data; (c)laboratory data;
            (d)radiology data; (e)pharmacy orders data; (f)e-prescribing
            data;(g) pharmacy dispensing data; (h) medical administration data;
            (i) administrative and operational data; (j) transaction data;
          </li>
          <li>
            Any other information that is collected or generated in the course
            of availing the Services;
          </li>
          <li>
            Data regarding your medical qualifications, registrations, and
            certifications;
          </li>
          <li>
            Any other detail relating to the above as voluntarily provided to us
            by you, for providing value-added service; and
          </li>
          <li>
            Any other information relating to the above which you may have
            shared with us prior to this Privacy Policy for availing any of the
            Services.
          </li>
        </ul>
      </span>
      <span>
        <strong>3.WHERE DO WE COLLECT YOUR DATA FROM</strong>
        <br />
        <span>For end users:</span>
        <ul style={{ listStyleType: "lower-alpha" }}>
          <li>
            Any information that you voluntarily choose to provide to us through
            the App, Website, email, during interaction with us on call or chat,
            and other modes of communication;
          </li>
          <li>
            Information that we collect from healthcare service providers
            (“HSPs”) such as doctors, hospitals, diagnostic centres, chemists,
            etc.;
          </li>
          <li>
            Data you have provided to any group company of the Company,
            affiliates, associates, subsidiary, holding company of the Company,
            associates, and subsidiaries of holding company of the Company, to
            whom you have given consent for sharing of such information.
          </li>
        </ul>
        <span>For doctors:</span>
        <ul style={{ listStyleType: "lower-alpha" }}>
          <li>
            Before Onboarding you, we may request information regarding your
            qualifications, experience, public profile, and representations made
            by you to us;
          </li>
          <li>
            During your usage of the App /Website, we may collect information
            based on your use of the Services;
          </li>
          <li>
            We may collect other information that you voluntarily choose to
            provide to us through the App, Website, email, during interaction
            with us on call or chat, and other modes of communication;
          </li>
          <li>
            We may collect information from any group company, affiliates,
            associates, subsidiary, holding company of the Company, associates,
            and subsidiaries of holding company of the Company to whom you have
            given consent for sharing of information for availing value-added
            service.
          </li>
        </ul>
      </span>
      <span>
        <strong>4. HOW DO WE USE YOUR DATA</strong>
        <br />
        We use your personal information except information received from Google
        APIs for purposes that include the following:
        <br />
        <span>(a) General (end users and doctors):</span>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>
            Your registration for the purpose of receiving our Services,
            identification, communication, notification, and for the fulfillment
            of the Terms and Conditions.
          </li>
          <li>
            Offering you personalized Services and targeted advertisements of
            various healthcare and wellness plans and offering you customized
            health insights;
          </li>
          <li>
            Addressing your requests, queries, and complaints, if any,
            pertaining to our Services; taking feedback, assisting you with the
            completion of transactions or other issues relating to the use of
            Services and other customer care-related activities;
          </li>
          <li>
            Creating insights for corporate/business strategy and marketing
            operations.
          </li>
          <li>
            Developing machine learning algorithms and tools to improve
            targeting of services, diagnostics, and treatment protocols, and
            other products and services;
          </li>
          <li>
            Contacting you to provide information on new Services, features,
            products, special promotions or offers, both of the 21VHPL entities
            and affiliates as well as third-party offers or products with whom
            we have a tie-up and which are relevant to the use of the Services;
          </li>
          <li>
            Technical administration and customization of Website, and other
            general administrative and business purposes;
          </li>
          <li>
            Research (internal or otherwise), fraud, security, risk management,
            and analysis for the development and improvement of products and
            services including the provision of tokenization services offered by
            Payment Aggregators/ Card Networks, etc. as per Card Networks Rules;
          </li>
          <li>
            Disclosure as required to government authorities in compliance with
            applicable law;
          </li>
          <li>
            Carrying out our obligations in relation to any agreement with
            affiliate companies, our business partners, or contractors
            including, but not limited to, the provision of tokenization
            services to Payment Aggregators / Card Networks / Card Issuers, on a
            global basis as necessary to provide the tokenization services, or
            for compliance with applicable laws, regulatory requirements or
            investigations;
          </li>
          <li>
            Investigating, enforcing, and resolving any disputes or grievances;
          </li>
          <li>Any other purpose required by applicable law.</li>
        </ol>
        <span>(b) For end users only:</span>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>
            Creation and maintenance of health records in electronic form in the
            Personal Health Record (PHR) database for use by us and the 21VHPL
            subsidiaries, affiliates, etc., to provide relevant services;
          </li>
          <li>
            Create your unified profile with analytics and insights generated
            through processing your personal information;
          </li>
          <li>
            For sharing with your chosen HSP partnered with 21VHPL like doctors,
            hospitals, diagnostic centres, and chemists who may provide you
            services under the App or Website;
          </li>
          <li>
            Processing any orders/requests you may place using our Services.
          </li>
        </ol>
        <span>(c) For doctors only:</span>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>
            For verifying your professional credentials and any representations
            you have made to us;
          </li>
          <li>For processing any payments made to you;</li>
          <li>
            For providing recommendations to end users based on your expertise
            and specializations;
          </li>
          <li>For providing any other service to you.</li>
        </ol>
      </span>
      <span>
        <strong>5. HOW LONG WILL WE RETAIN YOUR DATA </strong>
        <ul style={{ listStyleType: "none" }}>
          <li>
            We store your personal information in accordance with applicable
            laws, which means we keep your data for as long as necessary to
            provide you with our Services or as may be required under any law.
            We shall store your personal information for lawful purposes only.
            We keep de-identified data for research and statistical purposes for
            a longer period. If you close your account, we have no obligation to
            retain your data, and we may delete any or all of your data without
            liability. However, we may retain data related to you if we believe
            it may be necessary to prevent fraud or future abuse, if required by
            law, or for other legitimate purposes. We may continue to store your
            data in anonymized or de-identified form for analytical, research,
            or other purposes for which your information is collected as
            previously indicated.
          </li>
        </ul>
      </span>
      <span>
        <strong>6.DISCLOSURE AND TRANSFER OF YOUR DATA</strong>
        <br />
        <ul style={{ listStyleType: "none" }}>
          <li>
            We may share, disclose, and in some cases transfer your personal
            information except information received from Google APIs to entities
            as required to provide Services to you, improve our Services, and
            provide value-added services or other third-party products and
            services, to the extent permitted by applicable law. These entities
            may be located outside India, which you hereby consent to. We
            require such entities to protect your information through equivalent
            security measures as we would adopt. An indicative list of entities
            we may disclose or transfer information except information received
            from Google APIs is provided below:
          </li>
        </ul>

        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>
            <span>Service Providers:</span>
            <span>
              We share personal information with companies that provide Services
              on our behalf, such as website hosting, data storage, software
              services, email services, marketing, fulfilling customer orders,
              providing payment-related services including payment aggregation,
              data analytics, data mining, providing customer services, and
              conducting surveys, as permitted by applicable law. These
              companies may be located within or outside India but, in any case,
              are obligated to protect your data.
            </span>
            <span>
              We may also share information with employees, data processors,
              consultants, business partners, and technology partners on a
              need-to-know basis. Such entities would be contractually obligated
              to maintain confidentiality in relation to your data.
            </span>
            <span>
              If you are an end user, your personal information will also be
              shared with your chosen HSPs.
            </span>
          </li>
          <li>
            <span>Business Affiliates:</span>
            <span>
              We may disclose or transfer some of your information to entities
              in the 21VHPL subsidiaries, affiliates, associates, subsidiaries,
              the holding company of the Company, associates, and subsidiaries
              of the holding company of the Company, including foreign entities,
              and in particular group companies and affiliates who are involved
              in the provision of products and services, to the extent permitted
              by applicable law.
            </span>
            <span>
              In the event of a merger, reorganization, acquisition, joint
              venture, assignment, spin-off, transfer, asset sale, or sale or
              disposition of all or any portion of our business, including in
              connection with any bankruptcy or similar proceedings, we may
              transfer any and all personal information to the relevant third
              party with the same rights of access and use.
            </span>
          </li>
          <li>
            <span>Law Enforcement Agencies:</span>
            <span>
              We may share information with law enforcement agencies pursuant to
              lawful requests for information and otherwise as required under
              any law applicable at the given time, both in India and outside
              India.
            </span>
          </li>
          <li>
            <span>Other Third Parties:</span>
            <span>
              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>
                  We may also disclose personal information if we determine in
                  good faith that disclosure is reasonably necessary to protect
                  our rights and pursue available remedies, enforce our terms
                  and conditions, investigate fraud, or protect our operations
                  or users;
                </li>
                <li>
                  We may disclose personal information to any third party if
                  necessary to provide or improve our Services, fulfill any
                  lawful contractual obligation we are bound by, and any other
                  activity related to the purposes identified in this privacy
                  policy and the terms and conditions you agree to when you use
                  our Services; and
                </li>
                <li>
                  Anonymized, aggregated data may be shared with advertisers,
                  research firms, and other partners.
                </li>
              </ul>
            </span>
          </li>
        </ol>
      </span>
      <span>
        <strong>7. HOW DO WE PROTECT YOUR DATA</strong>
        <ul style={{ listStyleType: "none" }}>
          <li>
            We are committed to maintaining the privacy of the information
            uploaded by you on the Website and complying with the
            industry-standard security safeguards for the purpose of securing
            the Website and the information provided/uploaded by you. We use
            reasonable technical, administrative, and physical security measures
            for the purpose of safeguarding all data you share with us. We also
            have comprehensive internal policies in place to prevent
            unauthorized access to your data. We take adequate steps to ensure
            that third parties we share data with also adopt reasonable levels
            of security practices and procedures to ensure the privacy and
            security of your information. However, we are not responsible for
            any loss, unauthorized access, safety issue, or any harm caused to
            you by any misuse of your personal information, unless it is a
            direct and foreseeable consequence of negligence and non-compliance
            on our part only. You hereby acknowledge that we are not
            responsible, in particular, for any third-party action or action on
            your part leading to loss, damage, or harm to you or any other
            person. For any data loss or theft due to unauthorized access to
            your electronic devices through which you avail our Services, the
            Company shall not be held liable for any loss whatsoever incurred by
            you. Further, you are liable to indemnify the Company as per the
            Terms of Use.
          </li>
        </ul>
      </span>
      <span>
        <strong>8. WHAT ARE YOUR RIGHTS</strong>
        <br />
        <ul style={{ listStyleType: "none" }}>
          <li>
            {" "}
            We take reasonable steps to ensure that your personal information is
            accurate, complete, and up to date. However, you have the sole
            responsibility of ensuring that you review the accuracy of the
            information provided by you and contact us in case of discrepancies,
            or in case you wish to discontinue the use of our Services.
          </li>
          <li>
            <strong>Your Rights:</strong>
          </li>
          <li>
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                You have the right to access your personal information and
                request updation, correction, and deletion. If your personal
                information changes, you may correct, delete inaccuracies, or
                amend information by making the change on our member information
                page or by contacting us. We will make good faith efforts to
                make requested changes in our then active databases as soon as
                reasonably practicable.
              </li>
              <li>
                If you provide any information that is untrue, inaccurate, out
                of date, or incomplete (or subsequently becomes untrue,
                inaccurate, out of date or incomplete), or we have reasonable
                grounds to suspect that the information provided by you is
                untrue, inaccurate, out of date or incomplete, we may, at our
                sole discretion, discontinue the provision of the Services to
                you.
              </li>
              <li>
                There may be circumstances where we will not correct, delete or
                update your personal information, including
                <ul style={{ listStyleType: "lower-alpha" }}>
                  <li>
                    where the personal information is opinion data that is kept
                    solely for evaluative purposes;
                  </li>
                  <li>
                    the personal information is in documents related to a
                    prosecution if all proceedings relating to the prosecution
                    have not been completed;
                  </li>
                  <li>
                    where your information has already been processed in
                    de-identified form.
                  </li>
                </ul>
              </li>
              <li>
                You are free not to share any medical or other information that
                you consider confidential and withdraw consent for us to use
                data that you have already provided. In the event that you
                refuse to share any information or withdraw consent to process
                information that you have previously given to us, we reserve the
                right to restrict or deny the provision of our Services for
                which we consider such information to be necessary.
              </li>
            </ol>
          </li>
        </ul>
      </span>
      <span>
        <strong>9. THIRD-PARTY WEBSITES AND SERVICES</strong>
        <ul style={{ listStyleType: "none" }}>
          <li>
            Our Website and App may contain links to third-party services, and
            give you the ability to access such third-party websites, products,
            and services. Please note that you may proceed to the use of such
            third-party websites or services at your own risk and the Company
            will not be held liable for any outcome or harm arising as a result
            of your use of such third-party websites or services. Please read
            the privacy policies of any third party before proceeding to use
            their websites, products, or services.
          </li>
        </ul>
      </span>
      <span>
        <strong>10. COMPLIANCE WITH THE GOOGLE USER DATA POLICY</strong>
        <ul style={{ listStyleType: "none" }}>
          <li>
            21VHPL’s use of information received from Google APIs will adhere to
            Google API Services User Data Policy including the Limited Use
            requirements and Limited Use Requirements shall apply to both raw
            data obtained from Restricted and Sensitive Scopes and data
            aggregated, anonymized, or otherwise derived from that raw data.
            Google API Services User Data Policy is available at{" "}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google API Services User Data Policy
            </a>
            .
          </li>
        </ul>
      </span>
      <span>
        <strong>11. CHANGES TO THIS PRIVACY POLICY</strong>
        <ul style={{ listStyleType: "none" }}>
          <li>
            Any changes to our Privacy Policy will be posted on the Website/ App
            and will become effective as of the date of posting. Please review
            the Privacy Policy from time to time to make sure you are aware of
            any changes. If you do not agree with any such revised terms, please
            refrain from using our Services and contact us to close any account
            you may have created.
          </li>
        </ul>
      </span>

      <div
        style={{
          backgroundColor: "rgb(36 50 93)",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <h1 style={{ color: "white" }}>PillzerHealth</h1>

        <span style={{ color: "white" }}>
          Copyright © 2023, PillzerHealth. All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default PrivacyAndPolicy;
