import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "rgb(36 50 93)",
        padding: "10px",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <p style={{ color: "white" }}>
        <Link
          to="/contact"
          style={{ textDecoration: "underline", color: "white" }}
        >
          {" "}
          Contact us:
        </Link>{" "}
        Phone - 8989607090 | Email - myhealth@pillzer.health |{" "}
        <Link
          to="/terms&conditions"
          style={{ textDecoration: "underline", color: "white" }}
        >
          Terms & Conditions
        </Link>{" "}
        |{" "}
        <Link
          to="/privacy&policy"
          style={{ textDecoration: "underline", color: "white" }}
        >
          Privacy Policy
        </Link>{" "}
        |{" "}
        <Link
          to="/payment&refundpolicy"
          style={{ textDecoration: "underline", color: "white" }}
        >
          payment&refundpolicy
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
